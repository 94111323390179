import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, retry, catchError, retryWhen } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConfig } from '../../app.config';
import { WebService } from './web.service';

@Injectable()
export class UserService {

  auth:any;

  constructor(
    private http: HttpClient,
    private webService: WebService,
  ) {}

  login(username:string, password:string): Observable<any> {
    this.auth = null;
    return this.http.get<any[]>(AppConfig.settings.endpoint.api + "auth/login?username=" + username + "&password=" + password)
      .pipe(
        map((data: any) => {
          if (data && data.token) {
            this.auth = data;
            this.webService.setHeaders({
              Authorization: 'Bearer ' + data.token
            });
            Object.keys(this.auth).map(key => {
              localStorage.setItem(key, this.auth[key]);
            });
          }
          return this.auth;
        }),
        catchError(this.handleError('login'))
      );
  }

  checkLogin():boolean {
    return this.getUser() != null;
  }

  canActivate():boolean {
    return false;
  }
  
  getUser():any {
    if (this.auth) {
      return this.auth;
    }
    if (localStorage.getItem('token')) {
      // Check expiration
      let expiration = Date.parse(localStorage.getItem('expiration'));
      if (expiration >= Date.now()) {
        this.auth = {
          username: localStorage.getItem('username'),
          token: localStorage.getItem('token'),
          authorities: localStorage.getItem('authorities'),
          expiration: localStorage.getItem('expiration'),
        }
        this.webService.setHeaders({
          Authorization: 'Bearer ' + this.auth.token
        });
        return this.auth;
      }
    }
    return null;
  }

  logout() {
    this.auth = null;
    localStorage.clear();
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
