import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../@core/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate() {
    if (this.userService.checkLogin()) {
      return true;
    }

      this.router.navigateByUrl('/auth/login');
      return false;
  }

}