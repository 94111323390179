import { Injectable } from '@angular/core';

@Injectable()
export class Utility {
  static _year:Number;

  public static sortJSON(data:any, key:string, asc:boolean = true):any {
    return data.sort(function(a, b) {
        var x = a[key] == null ? '' : a[key];
        var y = b[key] == null ? '' : b[key];
        if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }
        else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }
    });
  }

  public static findByKey(data:Array<any>, key:string, value:any):any {
    for (let item of data) {
      if (item[key] === value) {
        return item;
      }
    }
    return null;
  }

  public static convertRgba(rgb:string, alpha:number):string {
    let result = null;
    if (rgb.length == 7) {
      result = 'rgba(' + parseInt(rgb.substr(1, 2), 16) + ',' + parseInt(rgb.substr(3, 2), 16) + ',' + parseInt(rgb.substr(5, 2), 16) + ',' + alpha + ')';
    }
    return result;
  }

  public static toCurrencyString(num) {
    var nStr = num.toString();
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (x2) {
      var n2 = Number(x2);
      x2 = n2.toFixed(2).substr(1);
    }
    return x1 + x2;
  }

  public static currentYear() {
    return ;
  }

  public static get year() {
    if (this._year == null) {
      this._year = (new Date()).getFullYear();
    }
    return this._year;
  }
  public static set year(value) {
    this._year = value;
  }

}
