import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map, retry } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { Router } from '@angular/router';

@Injectable()
export class WebService {
  credentialHeaders;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  getHeaders(): any {
    return this.credentialHeaders;
  }
  setHeaders(credentialHeaders) {
    this.credentialHeaders = credentialHeaders;
  }

  get(instance: string, query: string = ''): Observable<any> {
    return this.http.get<any[]>(AppConfig.settings.endpoint.api + instance + query, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        retry(3),
        catchError(this.handleError('get', [])),
      );
  }

  post(instance: string, param: any): Observable<any> {
    return this.http.post(AppConfig.settings.endpoint.api + instance, param, {
      headers: this.getHeaders(),
    })
    .pipe(
      map((data: any) => {
        return data;
      }),
      retry(3),
      catchError(this.handleError('post', [])),
    );
  }

  put(instance: string, param: any): Observable<any> {
    return this.http.put(AppConfig.settings.endpoint.api + instance, param, {
      headers: this.getHeaders(),
    })
    .pipe(
      map((data: any) => {
        return data;
      }),
      retry(3),
      catchError(this.handleError('put', [])),
    );
  }

  patch(instance: string, param: any): Observable<any> {
    return this.http.patch(AppConfig.settings.endpoint.api + instance, param, {
      headers: this.getHeaders(),
    })
    .pipe(
      map((data: any) => {
        return data;
      }),
      retry(3),
      catchError(this.handleError('patch', [])),
    );
  }

  delete(instance: string): Observable<any> {
    return this.http.delete(AppConfig.settings.endpoint.api + instance, {
      headers: this.getHeaders(),
    })
    .pipe(
      map((data: any) => {
        return data;
      }),
      retry(3),
      catchError(this.handleError('delete', [])),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        if (this.router.url.indexOf('/auth/login') !== 0) {
          this.router.navigate(['/auth/login', { redirect_url: encodeURIComponent(this.router.url) }]);
        }
      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
