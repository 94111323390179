import { NbJSThemeOptions, CORPORATE_THEME as baseTheme } from '@nebular/theme';

const baseThemeVariables = baseTheme.variables;

export const CORPORATE_THEME = {
  name: 'corporate',
  base: 'corporate',
  variables: {

    echarts: {
      bg: baseThemeVariables.bg,
      color: baseThemeVariables.fgText,
      textColor: baseThemeVariables.fgText,
      fontFamily: null,//'"Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',

      legendTextColor: baseThemeVariables.fgText,
      legendFontSize: '14',

      tooltipBg: baseThemeVariables.bg,
      tooltipLineColor: 'rgba(0, 0, 0, 0)',
      tooltipLineWidth: '0',
      tooltipBorderColor: baseThemeVariables.border3,
      tooltipBorderWidth: '1',
      tooltipExtraCss: 'border-radius: 10px; padding: 8px 24px;',
      tooltipTextColor: baseThemeVariables.fgText,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '16',

      axisLineColor: baseThemeVariables.border4,
      axisFontSize: '12',
      axisTextColor: baseThemeVariables.fg,
      axisSplitLine: baseThemeVariables.separator,
      axisSplitColors: [baseThemeVariables.bg, baseThemeVariables.bg2],

      colors: [
        baseThemeVariables.primary,
        baseThemeVariables.success,
        baseThemeVariables.warning,
        baseThemeVariables.info,
        baseThemeVariables.danger,
        baseThemeVariables.primaryLight,
        baseThemeVariables.successLight,
        baseThemeVariables.warningLight,
        baseThemeVariables.infoLight,
        baseThemeVariables.dangerLight,
      ],

      gradColors: [
        baseThemeVariables.primaryLight,
        baseThemeVariables.successLight,
        baseThemeVariables.warningLight,
        baseThemeVariables.infoLight,
        baseThemeVariables.dangerLight,
        baseThemeVariables.primary,
        baseThemeVariables.success,
        baseThemeVariables.warning,
        baseThemeVariables.info,
        baseThemeVariables.danger,
      ],

      heroColors: [
        baseThemeVariables.primary,
        '#8f9bb3',
        '#c5cee0',
      ],
      heroGradColors: [
        baseThemeVariables.primaryLight,
        '#a7b1c5',
        '#e4e9f2',
      ],

      itemBorderColor: baseThemeVariables.primary,
      lineStyle: 'solid',
      lineWidth: '4',

      sentiColors: [
        baseThemeVariables.success,
        baseThemeVariables.primary,
        baseThemeVariables.danger,
      ],
      sentiGradColors: [
        baseThemeVariables.successLight,
        baseThemeVariables.primaryLight,
        baseThemeVariables.dangerLight,
      ],
      kpiColors: [
        baseThemeVariables.success,
        baseThemeVariables.primary,
        baseThemeVariables.danger,
      ],
      
      secondaryBackgroundColor: baseThemeVariables.bg2,
    },

  },
} as NbJSThemeOptions;
